<template>
  <div class="sys-detail-wrap">
    <div class="page-header">
      <div class="page-header-back" @click="routerBack">
        <i class="iconfont el-icon-arrow-left"></i>
      </div>
      <div class="page-header-title">{{ state.title }}</div>
    </div>
    <div class="meta-info">
      <div class="meta-info-item">消息类型：系统消息</div>
      <div class="meta-info-item">发布时间：{{ state.createTime }}</div>
    </div>
    <div class="page-content">
      <div class="content-text" v-html="state.content"></div>
      <template v-if="state.fileId">
        <div class="divider">附件</div>
        <div class="annex-wrap">
          <i class="iconfont iconshangchuanfujian"></i>
          <div class="annex-name" @click="onAnnexClick">{{ state.fileName }}</div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { onActivated, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getBulletinInfo } from '@/apis'
import { downFile } from '@/common/utils'
export default {
  name: 'sysDetail',
  setup() {
    const router = useRouter()
    const route = useRoute()
    let id = route.params.id
    const companyNo = route.params.companyNo
    const state = reactive({
      title: '',
      content: `
      `,
      createTime: '2020-12-23 22:31',
      fileId: '',
      fileName: ''
    })
    async function initBulletinInfo(msgId) {
      try {
        const result = await getBulletinInfo({ id: msgId })
        console.log('result', result)
        const { data = {} } = result
        state.title = data.title
        state.content = data.content
        state.createTime = data.createTime
        state.fileId = data.fileId
        state.fileName = data.fileName
      } catch (error) {
        console.log('ini', error)
      }
    }
    onActivated(() => {
      console.log('----onActivated------', id)
      id = route.params.id
      if (id) {
        initBulletinInfo(id, companyNo)
      }
    })
    const routerBack = () => {
      router.go(-1)
    }
    const onAnnexClick = async () => {
      if (!state.fileId) return false
      const url = `/file/download?id=${state.fileId}`
      await downFile(url, state.fileName)
    }
    return {
      state,
      routerBack,
      onAnnexClick
    }
  }
}
</script>
<style lang="scss" scoped>
@import './sys-detail.scss';
</style>
