export * from './util'
/**
 * 广度优先树型结构查找
 * @param {*} list 属性
 * @param {*} target 目标对象
 * @param {*} key 目标值key
 * @param {*} children 孩子的key
 * @returns
 */
export const bfsFind = (list, target, key, children = 'children') => {
  if (!Array.isArray(list)) throw new Error('argment[0] must be Array')
  const queue = [...list]
  while (queue.length !== 0) {
    const item = queue.shift()
    if (item[key] === target[key]) {
      return item
    }
    if (Array.isArray(item[children])) {
      item[children].forEach(child => {
        queue.push(child)
      })
    }
  }
  return null
}

/**
 * 修改树形数据
 * @param {*} list
 * @param {*} key
 * @param {*} value
 * @param {*} children
 * @returns
 */
export const bfsTree = (list, key, value, children = 'children') => {
  if (!Array.isArray(list)) throw new Error('argment[0] must be Array')
  const queue = [...list]
  while (queue.length !== 0) {
    const item = queue.shift()
    item[key] = value
    if (Array.isArray(item[children])) {
      item[children].forEach(child => {
        queue.push(child)
      })
    }
  }
  return null
}

/**
 * 下载文件
 * @param {*} url 文件url
 * @param {*} name 文件名称
 */
export const downFile = (url, name) => {
  return new Promise(resolve => {
    const elink = document.createElement('a')
    elink.download = name
    elink.style.display = 'none'
    elink.href = url
    document.body.appendChild(elink)
    elink.click()
    URL.revokeObjectURL(elink.href) // 释放URL 对象
    document.body.removeChild(elink)
    resolve(true)
  })
}

/**
 * 文件大小限制
 * @param {*} file
 * @param {*} limitSize
 * @returns
 */
export function limitFileSize(file, limitSize) {
  const arr = ['KB', 'MB', 'GB']
  const limit = limitSize.toUpperCase()
  let limitNum = 0
  for (let i = 0; i < arr.length; i++) {
    const leval = limit.indexOf(arr[i])
    if (leval > -1) {
      limitNum = parseInt(limit.substr(0, leval)) * Math.pow(1024, i + 1)
      break
    }
  }
  if (file.size > limitNum) {
    return false
  }
  return true
}

/**
 * 判断是否是允许类型
 * @param {*} file
 * @param {*} type
 */
export function acceptFile(file, accept) {
  const name = file.name.substr(file.name.lastIndexOf('.'))
  if (!name) return false
  return accept.split(',').includes(name)
}
/**
 * 获取图片的宽高
 * @param {*} file
 * @returns
 */
export function getFileBounds(file) {
  return new Promise((resolve, reject) => {
    if (!acceptFile(file, '.png,.jpg,.jpeg,.bmp')) {
      reject(new Error('the file must be an image'))
      return false
    }
    const reader = new FileReader()
    let fileWidth = 0
    let fileHeight = 0
    reader.onload = function (e) {
      var image = new Image()
      image.src = e.target.result
      image.onload = function () {
        fileHeight = this.height
        fileWidth = this.width
        resolve({ fileWidth, fileHeight })
      }
      image.onerror = function (e) {
        reject(e)
      }
    }
    reader.onerror = function (e) {
      reject(e)
    }
    reader.readAsDataURL(file)
  })
}

/**
 * 获取文件展示路径
 * @param {*} file
 * @returns
 */
export function getFileShowUrl(file) {
  return `/file/show/${file.id}`
}

/**
 * 防抖
 * @param {*} fn
 * @returns
 */
export function debounce(fn, time) {
  let timeout = null // 创建一个标记用来存放定时器的返回值
  return function () {
    clearTimeout(timeout) // 每当用户输入的时候把前一个 setTimeout clear 掉
    timeout = setTimeout(() => {
      // 然后又创建一个新的 setTimeout, 这样就能保证输入字符后的 interval 间隔内如果还有字符输入的话，就不会执行 fn 函数
      fn.apply(this, arguments)
    }, time)
  }
}
