export function timeFix() {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

/**
 * 手机号码脱敏
 * @param {*} str
 * @returns
 */
export function mobileNumberChange(str) {
  if (!str) return ''
  return str.replace(/(\d{3})\d*(\d{4})/, '$1****$2')
}

/**
 * 通过对象获取列表
 * @param {*} obj
 * @returns
 */
export function getListByObj(obj) {
  return Object.keys(obj).map(key => ({ id: key, value: obj[key] }))
}

/**
 * 滚动到提示错误的表单项
 */
export function locateToErr() {
  setTimeout(() => {
    const errorDiv = document.getElementsByClassName('is-error')
    errorDiv[0].scrollIntoView()
  }, 0)
}
